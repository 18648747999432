<template>
	<v-container>
		<v-card class="pa-5 mt-5 text-center" color="secondary">
			<v-card-title class="d-block text-h4 mb-5">subscription complete</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" class="d-flex justify-center">
						<img src="../../assets/toon_2.png" style="width: 50%; max-width: 200px;">
					</v-col>
					<v-col cols="12" class="text-center pt-5">
						<p>thank you for subscribing.<br>you can now enjoy full premium access.</p>
						<v-btn :to="{name: 'Home'}" color="accent" :loading="loading">home</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import {toast} from "@/utils/helpers";
import {sync} from "vuex-pathify";

export default {
	name: "StripeSuccess",
	data: () => ({
	    loading: true
	}),
	computed: {
		user: sync('user/user')
	},
	mounted() {
		this.success()
	},
	methods: {
		success() {
			this.loading = true;
			let session_id = this.$route.query.session_id;
			if (session_id) {
				this.$api.stripe.success(session_id)
					.then(response => this.user = response.data)
					.catch(error => toast.handleResponseErrors(error))
				.finally(() => this.loading = false)
			}
			else {
				toast.add('Sorry, something went wrong.', 'error');
				this.loading = false
			}
		}
	}
}
</script>